
    import Vue from 'vue';
    import Field from '@/components/Field.vue';

    import RCP from '@/api/RCP.ts';

    import Validator from '@/helpers/validator';

    import { Template, TemplateResponse } from '@/types/Template';
    import { ErrorResponse } from '@/types/Errors';

    export default Vue.extend({
        name: 'editor',
        data() {
            return {
                templateRules: {
                    name: 'required',
                    string_id: 'required'
                },
                errors: {
                    name: '',
                    string_id: ''
                },
                request: false,
                formRequest: false,
                loading: false,
                error: false
            };
        },
        created() {
            if (this.template.id !== this.templateId) {
                this.getTemplate();
            } else {
                this.loading = true;
            }
        },
        computed: {
            template(): Template {
                return this.$store.state.template.info;
            },
            templateId(): number {
                return +this.$route.params.id;
            }
        },
        methods: {
            getTemplate() {
                this.loading = false;
                this.error = false;

                RCP({
                    method: 'App.Template.get',
                    params: {
                        pk: this.templateId
                    }
                }).then((result: TemplateResponse) => {
                    if (result.item) {
                        this.$store.commit('template/set', result.item);
                    }

                    this.loading = true;
                }).catch((error: ErrorResponse) => {
                    if (error.show) {
                        alert('Ошибка : ' + error.message);
                    }

                    this.error = true;
                    this.loading = true;
                });
            },
            validate(submit = true) {
                if (!this.request) {
                    const validate = new Validator(this.template, this.templateRules).make();

                    this.errors = validate.errors;

                    if (validate.isValid && submit) {
                        this.updateTemplate();
                    } else {
                        this.formRequest = true;
                    }
                }
            },
            updateTemplate() {
                this.request = true;

                RCP({
                    method: 'App.Template.set',
                    params: {
                        attr: this.template
                    },
                    id: 'updateTemplate'
                }).then((result: TemplateResponse) => {
                    if (result.item) {
                        alert('Сохранено : Данные шаблона успешно обновлены');

                        this.$store.commit('template/set', result.item);
                        this.$router.push('/template/' + result.item.id);
                    }

                    this.request = false;
                }).catch((error: ErrorResponse) => {
                    if (error.show) {
                        alert('Ошибка : ' + error.message);
                    }

                    this.request = false;
                });
            }
        },
        watch: {
            formRequest(val) {
                if (val) {
                    this.$watch('template', () => {
                        this.validate(false);
                    }, {
                        deep: true
                    });
                }
            }
        },
        components: {
            Field
        }
    });
